<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          label=""
          outlined
          hide-details="false"
          dense
          v-model="cname"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          label=""
          outlined
          hide-details="false"
          dense
          v-model="cvalue"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-btn color="primary" elevation="2" @click="cset()">Action</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Root",

  data() {
    return {
      cname: "",
      cvalue: "",
    };
  },
  methods: {
    cset() {
      this.$cookie.set(this.cname, this.cvalue)
    },
  },
};
</script>
