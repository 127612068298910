import Vue from 'vue'
import VueRouter from 'vue-router'
import Root from '../views/Root.vue'
import VueCookie from 'vue-cookie'

Vue.use(VueRouter)
Vue.use(VueCookie)

const routes = [
  {
    path: '/',
    name: 'root',
    component: Root,
    meta: { title: 'YTL-MAS Management', desc: '' }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: { title: 'YTL-MAS Management', desc: '' }
  },
  {
    path: '/channels',
    name: 'channels',
    component: () => import('../views/Channels.vue'),
    meta: { title: 'チャンネル一覧 - YTL-MAS Management', desc: '', requiresAuth: true }
  },
  {
    path: '/streams',
    name: 'streams',
    component: () => import('../views/Streams.vue'),
    meta: { title: '配信一覧 - YTL-MAS Management', desc: '', requiresAuth: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  /*if (to.matched.some(record => record.meta.requiresAuth) && Vue.cookie.get('secret_token') == null) {
    next({ path: '/', query: { redirect: to.fullPath } });
  } else*/ {
    next();
  }
});

export default router
